@import "../../utils/app.scss";

.produits-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 60px;

  @media(max-width: 1200px) {
    padding: 20px 10px;
  }

  @media(max-width: $break_tablet) {
    padding: 20px 10px;
  }

  .titre {
    text-align: center;
    font-size: 16px;
  }

  .feed-buttons {
    align-self: flex-end;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    .feed {
      background-color: $bleu;
      color: #fff;
      cursor: pointer;
      padding: 10px 25px;
      align-self: flex-end;
      border-radius: 5px;
      font-size: 16px;

      &.alternate {
        background-color: red;
        margin-right: 20px;
      }
    }
  }
}

.produit-drawer {
  .ant-drawer-content-wrapper {
    width: 600px !important;

    @media(max-width: $break_tablet) {
      width: 100% !important;
    }

    .ant-drawer-header {
      background: $bleu;
      border-radius: 0px;

      .ant-drawer-title {
        color: #fff;
      }

      .ant-drawer-close {
        color: #fff;
      }
    }
  }
}
