@import "../../utils/app.scss";

.livraison-view-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 60px;

  @media(max-width: 1200px) {
    padding: 20px 10px;
  }

  @media(max-width: $break_tablet) {
    padding: 20px 10px;
  }

  .livraison-item-validate {
    background-color: $bleu;
    color: #fff;
    cursor: pointer;
    padding: 10px 25px;
    align-self: flex-end;
    border-radius: 5px;
    font-size: 16px;
  }

  .livraison-depense {
    color: #fff;
    padding: 10px 25px;
    align-self: flex-start;
    border-radius: 5px;
  }

  .livraison-view-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 500px;
    margin: 20px auto;

    .livraison-view-menu-item {
      width: auto;
      margin: 0px 1%;
      font-size: 18px;
      cursor: pointer;

      &.active {
        color: $bleu;
        border-bottom: 2px solid $bleu;
      }
    }
  }

  .livraison-view-commande-block {
    display: block;
    width: 100%;

    .commande-block-hr {
      border-bottom: 2px solid $bleu;
      margin: 30px auto;
      width: 100%;
    }
  }

  .add-bon-de-livraison {
    background: #fff;
    border: 1px solid $bleu;
    color: $bleu;
    border-radius: 5px;
    margin: 10px 0px;
    padding: 10px 15px;
    cursor: pointer;
    width: 200px;
    text-align: center;

    &:hover {
      background-color: $bleu;
      color: #fff;
    }
  }
}
