@import "../../utils/app.scss";

.livraisons-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 60px;

  @media(max-width: 1200px) {
    padding: 20px 10px;
  }

  @media(max-width: $break_tablet) {
    padding: 20px 10px;
  }

  .titre {
    text-align: center;
    font-size: 16px;
  }
}
