@import "../../utils/app.scss";

.collecte-view-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 60px;
  
  .collecte-filtre-block {
    display: flex;
    align-self: flex-start;
    flex-wrap: nowrap;
    height: 50px;
    align-items: center;
    margin-left: 4%;

    @media(min-width: 1300px) {
      margin: 0px auto;
      width: 96%;
    }

    @media(max-width: $break_mobile) {
      margin-left: 0%;
    }

    .collecte-filtre {
      font-weight: 200;
      padding: 5px 15px;
      cursor: pointer;
      color: #000;

      &.active {
        color: $bleu;
        border: 1px solid $bleu;
        border-radius: 5px;
      }
    }
  }

  .cta-validate {
    background-color: $bleu;
    color: #fff;
    cursor: pointer;
    padding: 10px 25px;
    align-self: flex-end;
    border-radius: 5px;
    font-size: 16px;
  }
}
