@import "../../utils/app.scss";

.association-view-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 60px;

  .cta-validate {
    background-color: $bleu;
    color: #fff;
    cursor: pointer;
    padding: 10px 25px;
    align-self: flex-end;
    border-radius: 5px;
    font-size: 16px;
  }

  .logo {
    background-size: cover;
    background-position: 50%;
    height: 60px;
    width: 60px;

    @media(max-width: $break_mobile) {
      height: 90px;
      width: 90px;
    }
  }
}
