@import "../../utils/app.scss";

.collecte-view-block {
  margin: 20px 10px 40px;

  .collecte-view-display-block {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 900px;

    @media(max-width: $break_tablet) {
      width: 600px;
    }

    @media(max-width: $break_mobile) {
      width: 100%;
    }

    .collecte-view-display {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      width: 23%;
      margin: 10px 1%;
      cursor: auto;

      &.ghost {
        background-color: $gris_clair;
        border-radius: 5px;
        border: none;
        background: linear-gradient(90deg,#f2f2f2 25%,#e6e6e6 37%,#f2f2f2 63%);
        background-size: 400% 100%;
        animation: ant-skeleton-loading 1.4s ease infinite;
      }

      @media(max-width: $break_tablet) {
        width: 31%;
      }

      @media(max-width: $break_mobile) {
        width: 48%;
      }

      &.active {
        border: 2px solid $bleu;
      }

      .image {
        height: 170px;
        width: 100%;
        text-align: center;

        img {
          max-width: 100%;
        }
      }

      .collecte-view-name {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        border: 1px solid;

        &.active {
          background-color: $gris_clair;
          color: $bleu;
        }

        .titre {
          text-align: center;
          padding: 5px;
          margin-bottom: 0px;
        }

        .logo {
          background-size: cover;
          background-position: 50%;
          height: 60px;
          width: 60px;

          @media(max-width: $break_mobile) {
            height: 90px;
            width: 90px;
          }
        }
      }
    }
  }
}

.collecte-view-livraison-cta {
  background-color: $bleu;
  color: #fff;
  padding: 10px 15px;
  border-radius: 5px;
  width: 200px;
  text-align: center;
  cursor: pointer;
  font-size: 16px;
}

.collecte-view-livraison-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 900px;
  margin: 20px 10px 40px;

  @media(max-width: $break_tablet) {
    width: 600px;
  }

  @media(max-width: $break_mobile) {
    width: 100%;
  }

  .collecte-view-livraison {
    width: 31%;
    margin: 1%;
    display: block;
    color: $regular;
    border: 1px solid $regular;
    border-radius: 5px;

    @media(max-width: $break_tablet) {
      width: 48%;
    }

    @media(max-width: $break_mobile) {
      width: 98%;
    }

    .collecte-view-livraison-title {
      padding: 10px 15px;
      background: $bleu;
      color: #fff;
    }

    img {
      height: 200px;
      width: 100%;
    }

    .collecte-view-livraison-body {
      padding: 10px 15px;
      display: block;
    }
  }
}
