@import '~antd/dist/antd.css';

$bleu: #2b8bbc;
$bleu_clair: rgb(107, 182, 218);
$bleu_tres_clair: rgba(107, 182, 218, 0.1);
$gris_clair: #EFEEEE;
$gris_moyen_clair: #E1E1E1;
$gris_fonce: #858585;
$gris_tres_fonce: #2D2D2D;
$gris_tres_clair: #FBFAFA;
$regular: rgba(0, 0, 0, 0.65);

$font-family-pompiere: 'Pompiere', cursive;
$font-family-open-sans: 'Open Sans', sans-serif;

body {
  font-family: 'Open Sans', sans-serif;
}

$skeleton-color: #f2f2f2;
$skeleton-animation: ant-skeleton-loading 1.4s ease infinite;

$break_tablet: 900px;
$break_mobile: 767px;
