@import "../../utils/app.scss";

.produit-view-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 60px;

  @media(max-width: 1200px) {
    padding: 20px 10px;
  }

  @media(max-width: $break_tablet) {
    padding: 20px 10px;
  }

  .cadre-produit {
    border-radius: 5px;
    border: 1px solid $bleu;
    background: #fff;
    margin-top: 40px;
    margin-bottom: 40px;
    padding: 40px;

    @media(max-width: $break_tablet) {
      padding: 40px 30px;
    }

    .produit-view-titre {
      text-align: center;
      color: $bleu;
      width: 100%;
      margin-bottom: 40px;
    }
  }
}
